.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  box-sizing: border-box;
  background-color: #174195;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.3125;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  font-family: 'MaisonNeue-Bold', helvetica, sans-serif;
  transition: background 250ms;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #182B5E;
}
.button:active {
  background-color: #182B5E;
}
.togglenavigation {
  top: 60px;
  right: 50px;
  width: 40px;
  height: 40px;
}
.tlines {
  width: 40px;
  height: 12px;
  margin-top: 14px;
}
.tline--1 {
  top: 12px;
}
.tline--2 {
  bottom: 12px;
}
body.cb-toggle-target-active .tlines {
  transform: rotate(90deg);
}
body.cb-toggle-target-active .tline--1 {
  transform: rotate(45deg) translate(-5px, -5px);
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(-45deg) translate(-5px, 5px);
}
.mobile-navigation div.sub1 {
  margin-top: 205px;
  padding: 0 17.39130435%;
}
.desk {
  max-width: 85%;
}
.home {
  width: 180px;
  height: 80px;
  margin: 40px 0 25px -36px;
}
.logo-mobile {
  display: none;
}
.logo-desktop {
  display: block;
}
.logo-desktop .logo {
  width: 100%;
  height: auto;
}
#head {
  margin: 40px 0  0;
}
.cb-layout3 #head {
  width: 65.51724138%;
  max-width: 675px;
  margin-right: 105px;
  margin-top: -100px;
  padding: 50px 50px 50px 60px;
}
.farnorth--empty.cb-layout4 #head {
  margin-bottom: 40px;
}
.wrapper {
  font-size: 16px;
  line-height: 1.375;
}
.navbar {
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.navbar.navi--hidden {
  transform: translateY(-100%);
}
body.cb-toggle-target-active .navbar.navi--hidden {
  transform: none;
}
.section--header {
  padding-top: 145px;
}
.cb-layout1 .mood {
  width: 92.5%;
  margin-left: 7.5%;
  margin-top: 40px;
}
@media only screen and (min-width: 1412px) {
  .cb-layout1 .mood {
    width: calc(1200px + 50% - 600px);
    margin-left: calc(50% - 600px);
  }
}
.cb-layout1 .mood .downlink {
  display: block;
  position: absolute;
  left: -64px;
  left: -3.75%;
  margin-left: -18px;
  bottom: 0;
  width: 36px;
  height: 24px;
  background: url(/images/arrow-down-black.svg) no-repeat center bottom;
  background-size: 12px 12px;
  cursor: pointer;
}
.cb-layout3 .mood {
  margin-top: 40px;
}
.section--footer {
  padding: 20px 0;
}
.section--footer .cb-goto-top {
  float: right;
}
#services {
  float: left;
  flex-direction: row;
}
#services .meta {
  margin-bottom: 0;
  margin-right: 25px;
}
h1 {
  font-size: 56px;
  line-height: 1.19642857;
}
.cb-layout3 h1 {
  font-size: 32px;
  line-height: 1.1875;
}
.cb-layout3 h1 i {
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 1.375;
}
.cb-layout6 .area.base {
  font-size: 16px;
  line-height: 1.375;
}
.cb-layout6 .area.base h2 {
  font-size: 16px;
  line-height: 1.375;
}
.loud {
  font-size: 22px;
  line-height: 1.36363636;
}
.cb-layout1 .loud {
  font-size: 36px;
  line-height: 1.36111111;
}
.cb-layout4 .area.side {
  font-size: 32px;
  line-height: 1.375;
}
.unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.unit.form {
  margin-top: 40px;
}
.part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout1 .unit {
  margin-top: 20px;
  margin-bottom: 20px;
}
.cb-layout1 .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.cb-layout5 .area.main {
  width: 100%;
}
.cb-layout5 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout5 .area.main .cb-album .body,
.cb-layout5 .area.main .head,
.cb-layout5 .area.main .foot,
.cb-layout5 .area.main .part {
  margin-right: 2.85714286%;
  margin-left: 2.85714286%;
  width: 94.28571429%;
}
.cb-layout5 .area.main .tiny {
  width: 44.28571429%;
}
.cb-layout5 .area.main > .slim .part {
  width: 94.28571429%;
}
.cb-layout5 .area.main > .slim .tiny {
  width: 44.28571429%;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: 100%;
}
.cb-layout1 .area.base > .unit,
.cb-layout2 .area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base .cb-album .body,
.cb-layout2 .area.base .cb-album .body,
.cb-layout1 .area.base .head,
.cb-layout2 .area.base .head,
.cb-layout1 .area.base .foot,
.cb-layout2 .area.base .foot,
.cb-layout1 .area.base .part,
.cb-layout2 .area.base .part {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.cb-layout1 .area.base .tiny,
.cb-layout2 .area.base .tiny {
  width: 46.66666667%;
}
.cb-layout1 .area.base > .slim,
.cb-layout2 .area.base > .slim {
  width: 50%;
}
.cb-layout1 .area.base > .slim .head,
.cb-layout2 .area.base > .slim .head,
.cb-layout1 .area.base > .slim .foot,
.cb-layout2 .area.base > .slim .foot,
.cb-layout1 .area.base > .slim .part,
.cb-layout2 .area.base > .slim .part {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
  width: 93.33333333%;
}
.cb-layout1 .area.base > .slim.cb-album .body,
.cb-layout2 .area.base > .slim.cb-album .body {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
  width: 93.33333333%;
}
.cb-layout1 .area.base > .slim .tiny,
.cb-layout2 .area.base > .slim .tiny {
  width: 43.33333333%;
}
.cb-layout4 .area.farnorth {
  margin-top: 10px;
  margin-bottom: 30px;
}
.cb-layout1 .area.main {
  margin-top: 45px;
  margin-bottom: 45px;
}
.cb-layout2 .area.main,
.cb-layout6 .area.main {
  margin-top: 10px;
}
.cb-layout3 .area.main,
.cb-layout4 .area.main,
.cb-layout4 .area.base {
  width: 103.44827586%;
  margin-left: -1.72413793%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout3 .area.main .unit.pure.wide,
.cb-layout4 .area.main .unit.pure.wide,
.cb-layout4 .area.base .unit.pure.wide {
  width: 83.33333333%;
}
.cb-layout3 .area.main .unit.pure.wide .part,
.cb-layout4 .area.main .unit.pure.wide .part,
.cb-layout4 .area.base .unit.pure.wide .part {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
.cb-layout3 .area.main .unit.pure.wide .part.tiny,
.cb-layout4 .area.main .unit.pure.wide .part.tiny,
.cb-layout4 .area.base .unit.pure.wide .part.tiny {
  width: 46%;
}
.cb-layout3 .area.main .unit.pure.slim,
.cb-layout4 .area.main .unit.pure.slim,
.cb-layout4 .area.base .unit.pure.slim {
  width: 41.66666667%;
}
.cb-layout3 .area.main .unit.pure.slim .part,
.cb-layout4 .area.main .unit.pure.slim .part,
.cb-layout4 .area.base .unit.pure.slim .part {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
}
.cb-layout3 .area.main .unit.pure.slim .part.tiny,
.cb-layout4 .area.main .unit.pure.slim .part.tiny,
.cb-layout4 .area.base .unit.pure.slim .part.tiny {
  width: 40%;
}
.cb-layout3 .area.main .unit.seam.wide,
.cb-layout4 .area.main .unit.seam.wide,
.cb-layout4 .area.base .unit.seam.wide {
  width: 83.33333333%;
  padding-right: 16.66666667%;
}
.cb-layout3 .area.main .unit.seam.wide .part,
.cb-layout4 .area.main .unit.seam.wide .part,
.cb-layout4 .area.base .unit.seam.wide .part {
  width: 93.33333333%;
  margin-left: 3.33333333%;
  margin-right: 3.33333333%;
}
.cb-layout3 .area.main .unit.seam.wide .part.tiny,
.cb-layout4 .area.main .unit.seam.wide .part.tiny,
.cb-layout4 .area.base .unit.seam.wide .part.tiny {
  width: 57.77777778%;
}
.cb-layout3 .area.main .unit.seam.slim,
.cb-layout4 .area.main .unit.seam.slim,
.cb-layout4 .area.base .unit.seam.slim {
  width: 50%;
}
.cb-layout3 .area.main .unit.seam.slim .part,
.cb-layout4 .area.main .unit.seam.slim .part,
.cb-layout4 .area.base .unit.seam.slim .part {
  width: 93.33333333%;
  margin-left: 3.33333333%;
  margin-right: 3.33333333%;
}
.cb-layout3 .area.main .unit.seam.slim .part.tiny,
.cb-layout4 .area.main .unit.seam.slim .part.tiny,
.cb-layout4 .area.base .unit.seam.slim .part.tiny {
  width: 43.33333333%;
}
.cb-layout3 .area.main {
  margin-bottom: 30px;
}
.cb-layout4 .area.base {
  margin-bottom: 50px;
}
.cb-layout5 .area.main {
  width: 60.34482759%;
  margin-left: -1.72413793%;
  margin-top: 10px;
  margin-bottom: 50px;
}
.cb-layout1 .area.side {
  width: 104.13793103%;
  margin-left: -1.95402299%;
  margin-top: 95px;
  margin-bottom: 95px;
}
.cb-layout1 .area.side .unit {
  width: 46.24724062%;
  margin-left: 1.87637969%;
  margin-right: 1.87637969%;
}
.cb-layout4 .area.side {
  width: 82.75862069%;
  margin-left: 8.62068966%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.cb-layout5 .area.side {
  float: right;
  width: 39.65517241%;
  margin-top: 40px;
  margin-top: 50px;
  padding: 10px 40px;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: 103.44827586%;
  margin-left: -1.72413793%;
}
.cb-layout1 .area.base .unit.seam,
.cb-layout2 .area.base .unit.seam {
  width: 96.66666667%;
  margin-left: 1.66666667%;
  margin-right: 1.66666667%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout1 .area.base .unit.seam.slim,
.cb-layout2 .area.base .unit.seam.slim {
  width: 46.66666667%;
}
.cb-layout1 .area.base .unit.seam .body,
.cb-layout2 .area.base .unit.seam .body {
  width: 72.14285714%;
  margin-left: 27.85714286%;
  padding-top: 44.28571429%;
}
.cb-layout1 .area.base .unit.seam .part.pict,
.cb-layout2 .area.base .unit.seam .part.pict {
  width: 138.61386139%;
  border-right-width: 30px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict),
.cb-layout2 .area.base .unit.seam .part:not(.pict) {
  padding: 5px 20px 5px 30px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict):nth-child(2),
.cb-layout2 .area.base .unit.seam .part:not(.pict):nth-child(2) {
  padding-top: 20px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict):last-child,
.cb-layout2 .area.base .unit.seam .part:not(.pict):last-child {
  padding-bottom: 30px;
}
.cb-layout1 .area.base {
  margin-top: 45px;
  margin-bottom: 65px;
}
.cb-layout1 .area.base .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.cb-layout2 .area.base {
  margin-top: 0;
  margin-bottom: 50px;
}
.cb-layout2 .area.base .unit.seam {
  margin-top: 30px;
  margin-bottom: 30px;
}
.cb-layout6 .area.base {
  margin-bottom: 50px;
}
.cb-layout6 .area.base .unit:not(:first-child) .part.pict,
.cb-layout6 .area.base .unit.unit--immo-result .part.pict {
  width: 39.65517241%;
}
.cb-layout6 .area.base .unit:not(:first-child) .part.part--immo-text,
.cb-layout6 .area.base .unit.unit--immo-result .part.part--immo-text,
.cb-layout6 .area.base .unit:not(:first-child) .part.code,
.cb-layout6 .area.base .unit.unit--immo-result .part.code {
  float: right;
  width: 56.89655172%;
}
.cb-layout1 .flat div.link {
  padding-right: 30px;
}
.part.cb-googlemaps,
.part.cb--googlemaps {
  height: 500px;
}
.text-section--loud + .text-section {
  margin-top: 40px;
}
/*# sourceMappingURL=./screen-medium.css.map */